import React from 'react';
import {Route, Routes} from 'react-router-dom'
import { publicRoutes } from './Routes';
import ErrorPage from '../pages/error/ErrorPage';

const AppRouter = () => {
    return (
        <Routes>
            {publicRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={
                    <Component/>
                } exact/>
            )}
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    );
};

export default AppRouter;