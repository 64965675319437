import React, { useContext, useEffect, useState } from 'react'
import Utils from '../../services/utils'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import UserServices from '../../services/general/userServices'
import { toast } from 'react-toastify'
import img from "../../images/placeholder.png"

const CartProduct = observer(({card}) => {
    const {app, user} = useContext(Context);

    const [loading, setLoading] = useState();

    const [count, setCount] = useState();

    useEffect(() => {
        if(card.count){
            setCount(card.count)
        }
    }, [card.count])

    const plusCount = async () => {
        setCount(count+1);
        const data = {
            uid: user.uid,
            product_id: card.id,
            count: count+1,
            fields: card.fields,
            price_configs: card.price_configs
        }

        const res = await UserServices.cart.update(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16))
        }else{
            setCount(count)
        }
    }

    const minusCount = async () => {
        if(count > 1){
            setCount(count-1);
            const data = {
                uid: user.uid,
                product_id: card.id,
                count: count-1,
                fields: card.fields,
                price_configs: card.price_configs
            }
    
            const res = await UserServices.cart.update(data);
    
            if(res.statusCode === 200){
                user.setBasketChange(Math.random().toString(16))
            }else{
                setCount(count)
            }
        }
    }

    const deleteItem = async () => {
        setLoading(true);

        const data = {
            uid: user.uid,
            product_id: card.id,
            fields: card.fields,
            price_configs: card.price_configs
        }

        const res = await UserServices.cart.remove(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16));
            setLoading(false);
            toast.success(res.message)
        }else{
            toast.error(res.message)
        }
    }

  return (
    <div className='cart-product'>
        {loading &&
            <div className='loader-wrapper-absolute'> <span className="loader"></span> </div>
        }
        <span className='close' onClick={deleteItem}>
            <i className='icon-close'></i>
        </span>
        <div className='cart-product-wrapper'>
            <img src={card.image ? card.image : img} alt={card.title} title={card.title}
                width={60}
                height={60}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
            <div className='product-cart-content'>
                <h5>{card.title}</h5>
                <p>{card.subtitle}</p>
                <div className='cart-product-price'>
                    <div className='price'>
                        {card.old_price && <span>{Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</span>}
                        {Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}
                    </div>
                    <div className='input-count'>
                        <div className='input-btn' onClick={minusCount}>
                            <i className='icon-minus'></i>
                        </div>
                        <div className='value'>{count}</div>
                        <div className='input-btn' onClick={plusCount}>
                            <i className='icon-plus'></i>
                        </div>
                    </div>
                </div>
                <div className='elems-flex'>
                    {card.bonus && 
                        <span className='product-bonus'>
                            {card.bonus.number}{card.bonus.type == 1 ? '%' : '' } Б
                        </span>
                    }
                    {card.state && 
                        <span className='product-bonus' style={{background: `${card.state.subtitle}`}}>
                            {card.state.name}
                        </span>
                    }
                </div>
            </div>
        </div>
        {card.price_configs?.length >= 1 ?
            <div className='cart-product-bottom'>
                {app.localizationsItems?.add_taste}: 
                {card.price_configs.map((it, index) =>
                    <b key={index}> {it?.title};</b>
                )}
            </div>
            : <></>
        }
        {card.fields?.length >= 1 ?
            <div className='cart-product-bottom'>
                {card.fields?.map((field, index) =>
                    <span key={index}>{field.name}: <b>{field.value};</b> </span>
                )}
            </div>
            :
            <></>
        }
    </div>
  )
})

export default CartProduct