import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';

const DeleteAddressModal = observer(({show, setShow, id, fetchItems}) => {
    const handleClose = () => setShow(false);

    const [loading, setLoading] = useState();

    const {app} = useContext(Context);

    const handleDelete = async () => {
        setLoading(true);
        const res = await UserServices.addresses.delete(id);

        if (res.statusCode === 200) {
            fetchItems();
        }else{
            toast.error(res.message)
        }
        setLoading(false)
    };

  return (
    <Modal show={show} onHide={handleClose} centered className='delete-modal'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <div className={loading ? "delete-profile-modal isLoading" : "delete-profile-modal"}>
                <h3>{app.localizationsItems?.delete_address_content}</h3>
                <div className="row">
                    <div className="col-6">
                        <button className='main-btn btn-gray'>{app.localizationsItems?.cancel}</button>  
                    </div>
                    <div className="col-6">
                        <button className='main-btn' onClick={handleDelete}>{app.localizationsItems?.delete}</button>  
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default DeleteAddressModal