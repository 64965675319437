import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Context } from '../..';

const DroupdownLang = observer(() => {
    const {app} = useContext(Context);
    
    const handleChange = (lang) => {
        if(app.lang !== lang.slug){
            app.setLang(lang.slug)
        }
    }
  return (
    app.languages?.length > 1 &&
    <div>
        <Dropdown className='lang-droupdown' id="dropdown-basic">
            <Dropdown.Toggle>
                <i className='icon-globe' data-title={app.lang}></i>
                <span>{app.localizationsItems?.lang}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu >
                {app.languages.map((lang, index) =>
                    <Dropdown.Item key={index} onClick={() => handleChange(lang)}>{lang.title}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    </div>
  )
})

export default DroupdownLang