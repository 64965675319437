import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { Context } from '../..';
import useFetchItems from '../../hooks/useFetchItems';
import UserServices from '../../services/general/userServices';
import ProfileAddressCard from '../simple/ProfileAddressCard';
import AddAddressModal from '../modals/AddAddressModal';
import { useNavigate } from 'react-router-dom';
import AddressLoading from '../simple/AddressLoading';
import Pagination from '../simple/Pagination';

const Address = observer(() => {
    const {app} = useContext(Context);

    const [modalOpen, setModalOpen] = useState(false);

    const navigate = useNavigate();

    const handleShow = () => setModalOpen(true);

    const {
        items,
        fetchItems,
        loading,
        pageCount,
        params,
        handlePageClick,
    } = useFetchItems(UserServices.addresses.index)

  return (
    <div className='profile-dashboard'>
        <div className='flex button-back'>
            <i className='icon-chevron-left' onClick={() => navigate(`/${app.lang}/mob-user`)}></i>
            <h3>{app.localizationsItems?.all_addresses}</h3>
            <div className='red' onClick={handleShow}><i className='icon-plus'></i> {app.localizationsItems?.add_address}</div>
        </div>
        {loading ?
            <AddressLoading/>
            :
            <>
                {items?.items?.length >= 1 ?
                    <div className="address-grid">
                        {items.items.map((card, index) =>
                            <ProfileAddressCard card={card} fetchItems={fetchItems} key={index}/>
                        )}
                    </div>
                    :
                    <div><h5>{app.localizationsItems?.empty_data}</h5></div>
                }
                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                <div className='mob-add-address' onClick={handleShow}><i className='icon-plus'></i> {app.localizationsItems?.add_address}</div>
            </>
        }
        {modalOpen && <AddAddressModal show={modalOpen} setShow={setModalOpen} fetchItems={fetchItems}/>}
    </div>
  )
})

export default Address