import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form';
import { Context } from '../..';
import { toast } from 'react-toastify';
import UserServices from '../../services/general/userServices';
import EditAddressModal from '../modals/EditAddressModal';

const AddressCard = observer(({card, fetchItems, setAddress, deliveryZones}) => {
    const {app} = useContext(Context);

    const [show, setShow] = useState();

    const [loading, setLoading] = useState();

    const {register, formState: {errors}} = useFormContext();

    const deleteAddress = async (e) => {
        e.stopPropagation();
        setLoading(true);

        const res = await UserServices.addresses.delete(card.id);

        if (res.statusCode === 200) {
            fetchItems();
        }else{
            toast.error(res.message)
        }

        setLoading(false);
    }

    const editAddress = (e) => {
        e.stopPropagation();
        setShow(true);
    }

  return (
    <label className='address-card'>
        {loading &&
            <div className='loader-wrapper-absolute'> <span className="loader"></span> </div>
        }
        <div className='card-item'>
            <input className="form-check-input" type="radio" value={card.id} {...register('address_id', {required: true})} required onClick={() => setAddress(card)}/>
            <span>{app.localizationsItems?.street}: <b>{card.full_address}</b></span>
            <i className='icon-pen' onClick={(e) => editAddress(e)}></i>
        </div>
        {card.json_address && <div className='card-grid card-item'>
            <span>{app.localizationsItems?.apartment}: <b>{card.json_address.apartment}</b></span>
            <span>{app.localizationsItems?.entrance}: <b>{card.json_address.entrance}</b></span>
            <span>{app.localizationsItems?.floor}: <b>{card.json_address.floor}</b></span>
            <span>{app.localizationsItems?.code_domofon}: <b>{card.json_address.code}</b></span>
        </div>}
        <div className='card-item' onClick={e => deleteAddress(e)}>
            <span></span>
            <i className='icon-remove'></i>
        </div>
        {show && <EditAddressModal show={show} setShow={setShow} fetchItems={fetchItems} id={card.id} deliveryZones={deliveryZones}/>}
    </label>
  )
})

export default AddressCard