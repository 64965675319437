import React from 'react'
import ProfileMobTabs from '../../components/complex/ProfileMobTabs'

const MobProfile = () => {
  return (
    <div className='profile'>
        <div className="container">
            <ProfileMobTabs/>
        </div>
    </div>
  )
}

export default MobProfile