import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import PorfileBonus from '../simple/PorfileBonus';

const BonusModal = observer(({show, setShow}) => {
    const {app} = useContext(Context);
    const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} centered className='delivery-stock-modal'>
        <Modal.Body className='delivery-stock-content'>
            <i className='icon-close' onClick={handleClose}></i>
            <h5 className='mb-15'>{app.localizationsItems?.profile_bonus_title}</h5>
            <div className='bonus-modal'>
                <PorfileBonus isModal={true} setShow={setShow}/>
                <p>{app.localizationsItems?.profile_bonus_content}</p>
            </div>
            <button className='main-btn' onClick={handleClose}>{app.localizationsItems?.sure}</button>
        </Modal.Body>
    </Modal>
  )
})

export default BonusModal