import React, { useContext } from 'react'
import Utils from '../../services/utils'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const CheckoutProductCard = observer(({card}) => {
    const {app} = useContext(Context);

  return (
    <div className='checkout-product-card'>
        <div>
            <h6>{card.title} х {card.count}</h6>
            {card.fields?.map((el, index) =>
                el.value && <div key={index}>{el.name}: {el.value}</div>
            )}
        </div>
        <h6>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</h6>
    </div>
  )
})

export default CheckoutProductCard