import React, { useContext, useState } from 'react'
import img from "../../images/placeholder.png"
import Utils from '../../services/utils'
import ProductViewModal from '../modals/ProductViewModal';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import ProductCardButton from './ProductCardButton';

const RecommendedProductCard = observer(({card}) => {
    const {app} = useContext(Context);

    const [show, setShow] = useState();

  return (
    card &&
    <>
        <div className='recommended-card'>
            <img src={card.image ? card.image : img} 
                alt={card.title} 
                title={card.title} 
                onClick={() => setShow(true)}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
                loading='lazy'
            />
            <div className='recommended-card-title' onClick={() => setShow(true)}>{card?.title ? card.title : 'Ролл Золотой дракон'}</div>
            {card.old_price ? 
                <div className='recommended-card-old-price'>{Utils.numberWithSpaces(card.old_price ? card.old_price : 300)} {app.localizationsItems?.currency}</div> 
                : <></>
            }
            <div className='recommended-card-price'>
                {Utils.numberWithSpaces(card?.price ? card.price : 300)} {app.localizationsItems?.currency}
            </div>
            <ProductCardButton info={card} setShow={setShow}/>
        </div>
        {show && <ProductViewModal show={show} setShow={setShow} slug={card.slug}/>}
    </>
  )
})

export default RecommendedProductCard