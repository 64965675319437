import {makeAutoObservable} from "mobx";

export default class UserStore {
    constructor() {
        this._uid = null
        this._basketChange = null
        this._tokenChange = null
        this._wishlistUid = null
        this._wishlistCountChange = null
        this._cartProducts = []
        this._totalSum = 0
        this._isAuth = false
        this._user = {}
        this._token = null
        this._balance = 0
        this._favorites = []
        this._address = null
        makeAutoObservable(this)
    }

    setAddress(address) {
        this._address = address
    }

    setFavorites(favorites) {
        this._favorites = favorites
    }

    setUid(uid) {
        localStorage.setItem('uid', uid);
        this._uid = uid
    }

    removeUid() {
        localStorage.removeItem('uid');
        this._uid = ''
    }

    setBasketChange(count) {
        this._basketChange = count
    }

    setTokenChange(count) {
        this._tokenChange = count
    }

    setWishlistUid(wishlistUid) {
        localStorage.setItem('wishlistUid', wishlistUid);
        this._wishlistUid = wishlistUid
    }

    setWishlistCountChange(wishlistCountChange) {
        this._wishlistCountChange = wishlistCountChange
    }

    setCartProducts(products) {
        this._cartProducts = products
    }

    setTotalSum(total) {
        this._totalSum = total
    }

    setIsAuth(bool) {
        this._isAuth = bool
    }

    setUser(user) {
        this._user = user
    }

    setToken(token) {
        localStorage.setItem('token', token)
        this._token = token
    }

    setBalance(balance) {
        this._balance = balance
    }

    removeToken() {
        localStorage.removeItem('token')
        this._token = null
    }

    get address() {
        return this._address;
    }

    get favorites() {
        return this._favorites;
    }

    get uid() {
        return localStorage.getItem('uid');
    }

    get basketChange() {
        return this._basketChange
    }

    get tokenChange() {
        return this._tokenChange
    }

    get wishlistUid() {
        return localStorage.getItem('wishlistUid');
    }

    get wishlistCountChange() {
        return this._wishlistCountChange
    }

    get totalSum() {
        return this._totalSum
    }

    get cartProducts() {
        return this._cartProducts
    }

    get isAuth() {
        return this._isAuth
    }

    get user() {
        return this._user
    }

    get token() {
        return localStorage.getItem('token')
    }

    get balance() {
        return this._balance
    }
}