import React, { useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { observer } from 'mobx-react-lite';
import L from 'leaflet';
import pin from "../../images/11.png"

const MapComponentPickups = observer(({pickupAddresses, pickupItem, setPickupItem}) => {
    const mapRef = useRef();
    const [center, setCenter] = useState();

    useEffect(() => {
        if(localStorage.getItem('city')){
            const city = JSON.parse(localStorage.getItem('city'));
            city?.location?.length >= 1 ? setCenter(city.location) : setCenter([51.1729850769043,71.42532348632812])
        }
    }, [localStorage.getItem('city')])

    useEffect(() => {
        if(pickupItem?.location){
            mapRef?.current?.flyTo(
                pickupItem.location,
                16, {
                    duration: 1
                }
            );
        }
    }, [pickupItem])

    var myIcon = L.icon({
        iconUrl: pin,
        iconSize: [41, 41],
    });

  return (
    center &&
    <div className='position-relative'>
        <MapContainer center={center} zoom={12} ref={mapRef}>
            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" minZoom={5}
            />
            {pickupAddresses?.items?.map((pickup, index) =>
                <Marker position={pickup.location} key={index} icon={myIcon}
                    eventHandlers={{
                        click: (e) => {
                            setPickupItem(pickup);
                        }
                    }}
                >
                    <Popup>{pickup.title}</Popup>
                </Marker>
            )}
        </MapContainer>
    </div>
  )
})

export default MapComponentPickups