import React, { useContext, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';
import MapComponent from '../complex/MapComponent';
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook';
import AppServices from '../../services/general/appServices';

const AddAddressModal = observer(({show, setShow, fetchItems, deliveryZones}) => {
    const {app} = useContext(Context)
    
    const [address, setAddress] = useState();

    const handleClose = () => {
        setShow(false);
    }

    const {handleSubmit, getValues, register, formState: {errors}} = useForm();

    const [addressValue, setAddressValue] = useState('');

    const [location, setLocation] = useState();

    const dropdown = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdown, false);

    const [typingTimeout, setTypingTimeout] = useState(0);

    const [availableAddresses, setAvailableAddresses] = useState();

    const CustomSubmit = async () => {
        const newData = {
            json_address: {
                entrance: getValues('entrance') ? getValues('entrance') : null,
                floor: getValues('floor') ? getValues('floor') : null,
                apartment: getValues('apartment') ? getValues('apartment') : null,
                code: getValues('code') ? getValues('code') : null,
                address: addressValue,
                lat: address?.lat,
                lng: address?.lng
            },
            full_address: addressValue
        }

        const res = await UserServices.addresses.store(newData);

        if (res.statusCode === 200) {
            fetchItems();
            toast.success(app.localizationsItems?.success_add_address);
            setShow(false);
        }else{
            toast.error(res.message)
        }
    };

    const handleChange = (e) => {
        setIsActive(true);
        setAddressValue(e.target.value);

        clearTimeout(typingTimeout);

        setTypingTimeout(setTimeout(async () => {
            const res = await AppServices.suggest(e.target.value);

            if (res.statusCode === 200) {
                setAvailableAddresses(res.content?.items);
            }
        }, 700));
    }

    const handleSelectLocation = async (el) => {
        setIsActive(false);
        setAddressValue(el.title);
        const res = await AppServices.search(el.title);

        if(res.statusCode === 200){
            setLocation(res.content.geo)
        }else{
            toast.error(res.message)
        }
    }
    
  return (
    <Modal show={show} onHide={handleClose} centered className='address-modal'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <div className='address-modal-wrapper'>
                <div className='address-modal-container'>
                    <h3>{app.localizationsItems?.new_address}</h3>
                    <div>
                        <div className='form-label'>
                            <label>{app.localizationsItems?.address}</label>
                            <div className='search-values-container'>
                                <input 
                                    value={addressValue} 
                                    onChange={e => handleChange(e)} 
                                    placeholder={app.localizationsItems?.address} 
                                    className="input input-center"
                                ></input>
                                {isActive && <div className='search-values-items' ref={dropdown}>
                                    {availableAddresses?.map((el, index) =>
                                        <div className='search-values-item' key={index} onClick={e => handleSelectLocation(el)}>
                                            <div>{el.title}</div>
                                            <div className='search-values-item-sub'>{el.subtitle}</div>
                                        </div>
                                    )}
                                </div>}
                            </div>
                        </div> 
                        <div className="row">
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.entrance}</label>
                                    <input type="text" className="input" {...register('entrance')} placeholder={app.localizationsItems?.entrance}/>
                                </div> 
                            </div>
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.floor}</label>
                                    <input type="text" className="input" {...register('floor')} placeholder={app.localizationsItems?.floor}/>
                                </div> 
                            </div>
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.apartment}</label>
                                    <input type="text" className="input" {...register('apartment')} placeholder={app.localizationsItems?.apartment}/>
                                </div> 
                            </div>
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.code_domofon}</label>
                                    <input type="text" className="input" {...register('code')} placeholder={app.localizationsItems?.code_domofon}/>
                                </div> 
                            </div>
                        </div>
                        <div onClick={CustomSubmit} className='main-btn'>{app.localizationsItems?.save}</div> 
                    </div>
                </div>
                <div className='map-container'>
                    <MapComponent setAddress={setAddress} deliveryZones={deliveryZones} setAddressValue={setAddressValue} locationSelect={location}/>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default AddAddressModal