import React from 'react'

const StoriesLoading = () => {
    const skeletonItems = Array.from({ length: 5 }, (_, index) => (
        <div className='stories-loading-item' key={index}>
            <div className='stories-loading-img'></div>
        </div>
    ));

  return (
    <div className="container">
        <div className='stories-loading'>
            {skeletonItems}
            <div className='flare'></div>
        </div>
    </div>
  )
}

export default StoriesLoading