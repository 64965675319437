import React, { useEffect, useState } from 'react'
import Utils from '../../services/utils';

const DeliveryCart = ({total, deliveryPromocode}) => {
    const [progress, setProgress] = useState(0);
    const [isFull, setIsFull] = useState();
    const [remainder, setRemainder] = useState();

    useEffect(() => {
        if(deliveryPromocode?.min_price <= total){
            setProgress(100);
            setRemainder(null);
            setIsFull(true)
        }else{
            let tempProgress = (total*100)/Number(deliveryPromocode?.min_price);
            setProgress(tempProgress);
            setRemainder(Number(Number(deliveryPromocode?.min_price)-total));
            setIsFull(false)
        }
    }, [total])

  return (
    <>{isFull ?
        <></>
        :
        <div className='delivery-stock'>
            <div className='delivery-stock-top'>
                <div className='delivery-stock-title'>
                    <div className='output' style={{ clipPath: `polygon(0% 0%, ${progress}% 0, ${progress}% 100%, 0% 100%)` }}></div>
                    <div className='output-inner'></div>
                    <div className='inner'><i className='icon-delivery'></i></div>
                </div>
                <div>
                    <div className='delivery-stock-top-title'>Еще {Utils.numberWithSpaces(remainder)} тг</div>
                    <p>До бесплатной доставки</p>
                </div>
            </div>
        </div>}
    </>
  )
}

export default DeliveryCart