import React from 'react'

const OrderDetailLoading = () => {
  return (
    <div className='loading-orders'>
        <div className='block-title'></div>
        <div className='order-detail-loading'>
            <div className='block block-1'></div>
            <div className='block block-2'></div>
            <div className='block block-1'></div>
            <div className='block block-3'></div>
        </div>
        <div className='block-title'></div>
        <div>
            <div className='order-detail-loading-product'>
                <div className='block-img'></div>
                <div className='wd-100'>
                    <div className='block'></div>
                    <div className='block'></div>
                </div>
            </div>
            <div className='order-detail-loading-product'>
                <div className='block-img'></div>
                <div className='wd-100'>
                    <div className='block'></div>
                    <div className='block'></div>
                </div>
            </div>
            <div className='order-detail-loading-product'>
                <div className='block-img'></div>
                <div className='wd-100'>
                    <div className='block'></div>
                    <div className='block'></div>
                </div>
            </div>
        </div>
        <div className='flare'></div>
    </div>
  )
}

export default OrderDetailLoading