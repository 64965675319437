import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { Context } from '../..';
import { Modal } from 'react-bootstrap'
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

const EditProfileModal = observer(({show, setShow}) => {
    const {user, app} = useContext(Context);
    const handleClose = () => setShow(false);

    const {handleSubmit, setValue, register, control, formState: {errors}} = useForm();

    const navigate = useNavigate();

    const CustomSubmit = async (data) => {
        const res = await UserServices.profile.update(data);

        if (res.statusCode === 200) {
            toast.success('Данные успешно обновлены')
        }else{
            toast.error(res.message)
        }
    };

  return (
    <Modal show={show} onHide={handleClose} centered className='stories-modal'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <form className="delete-profile-modal" onSubmit={handleSubmit(CustomSubmit)}>
                <h3>{app.localizationsItems?.edit_profile}</h3>
                <div className='form-label'>
                    <label>{app.localizationsItems?.name}</label>
                    <input type="text" className="input" required {...register('name')} placeholder={app.localizationsItems?.name}/>
                </div> 
                <div className='form-label'>
                    <label>{app.localizationsItems?.phone}</label>
                    <Controller defaultValue="" name="phone" control={control}
                        render={({field: {onChange, value}}) => (
                            <ReactInputMask onChange={onChange} value={value}
                                mask="+7(999)999-99-99" maskChar="_">
                                {
                                    inputProps => (
                                        <input type="tel" className="input" placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                    )
                                }
                            </ReactInputMask>
                        )}
                        rules={{pattern: {
                            value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                        }}}
                    />
                </div> 
                <div className='form-label'>
                    <label>{app.localizationsItems?.email}</label>
                    <input type="email" className="input" required {...register('email')} placeholder={app.localizationsItems?.email}/>
                </div> 
                <div className="row">
                    <div className="col-6">
                        <button className='main-btn btn-gray'>{app.localizationsItems?.cancel}</button>  
                    </div>
                    <div className="col-6">
                        <button className='main-btn'>{app.localizationsItems?.save}</button>  
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default EditProfileModal