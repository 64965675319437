import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import Utils from '../../services/utils';
import PresentModal from '../modals/PresentModal';

const PresentStock = observer(({presentPromocode}) => {
    const {user} = useContext(Context);

    const [show, setShow] = useState();

    const [remainder, setRemainder] = useState();
    const [isFull, setIsFull] = useState();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if(user.cartProducts){
            let temp = 0;
            for(let i = 0; i < user.cartProducts.length; i++){
                temp += Number(user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
            }
            const minSumm = presentPromocode.value ? Number(presentPromocode.value[0].min_price) : Number(presentPromocode.min_price);

            if(minSumm <= temp){
                setProgress(100);
                setRemainder(null);
                setIsFull(true)
            }else{
                let tempProgress = (temp*100)/minSumm;
                setProgress(tempProgress);
                setRemainder(Number(minSumm-temp));
                setIsFull(false)
            }
        }
    }, [user.cartProducts])

  return (
    <div className={isFull ? 'delivery-stock present-stock present-stock-full' : 'delivery-stock present-stock'}>
        <div className='delivery-stock-title' onClick={() => setShow(!show)}>
            <div className='output' style={{ clipPath: `polygon(0% 0%, ${progress}% 0, ${progress}% 100%, 0% 100%)` }}></div>
            <div className='output-inner'></div>
            <div className='inner'><i className='icon-present'></i></div>
        </div>
        {show && <PresentModal show={show} setShow={setShow} remainder={remainder} isFull={isFull} progress={progress} presentPromocode={presentPromocode}/>}
    </div>
  )
})

export default PresentStock