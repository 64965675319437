import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import { Context } from '../..';
import DeliveryOption from '../../components/complex/DeliveryOption';
import PaymentOption from '../../components/complex/PaymentOption';
import UserInfo from '../../components/complex/UserInfo';
import OrderComment from '../../components/simple/OrderComment';
import SummaryCheckout from '../../components/complex/SummaryCheckout';
import UserAddress from '../../components/complex/UserAddress';
import CheckoutAddress from '../../components/complex/CheckoutAddress';
import { toast } from 'react-toastify';
import UserServices from '../../services/general/userServices';
import AuthServices from '../../services/general/authServices';
import LoginCodeModal from '../../components/modals/LoginCodeModal';
import { useNavigate } from 'react-router-dom';
//import DeliveryPoints from '../../components/complex/DeliveryPoints';

const CheckoutPage = observer(() => {
    const {user, app} = useContext(Context);

    const navigate = useNavigate();

    const [loading, setLoading] = useState();
    const [balance, setBalance] = useState(false);
    const [bonuseUse, setBonuseUse] = useState(false);

    const [codeShow, setCodeShow] = useState();
    const [phone, setPhone] = useState();

    const [deliveryZones, setDeliveryZones] = useState();

    const [orderData, setOrderData] = useState();

    const methods = useForm();

    const [delivery, setDelivery] = useState();

    const [address, setAddress] = useState();
    const [promocode, setPromocode] = useState('');

    const CustomSubmit = async (data) => {
        setLoading(true);
        const {address_id, ...rest} = data;

        let newData ={}

        if(user.token){
            newData= {
                ...rest,
                address_id,
                promocode: promocode,
                uid: user.uid,
                bonus_used: bonuseUse ? balance : 0
            }
        }else{
            newData= {
                ...rest,
                uid: user.uid,
                promocode: promocode,
                json_address: address?.json_address,
                full_address: address?.full_address,
            }
        }

        if(!user.token){
            const res = await AuthServices.logIn({phone: data.phone});
            if(res.statusCode === 200){
                setOrderData(newData);
                setPhone(data.phone);
                setCodeShow(true);
            }else{
                toast.error(res.message);
            }
        }else{
            const res = await UserServices.cart.order.order(newData);

            if(res.statusCode === 200){
                user.removeUid();
                if(res.content.user?.token){
                    user.setToken(res.content.user.token);
                }
                user.setBasketChange(Math.random().toString(16));
                if(res.content.checkout_url){
                    window.open(res.content.checkout_url, '_self');
                }else{
                    toast.success('Заказ успешно оформлен');
                    navigate(`/${app.lang}/user/orders`)
                }
            }else if(res.statusCode === 502){
                user.removeUid();
                user.setBasketChange(Math.random().toString(16));
                navigate(`/${app.lang}/order-error`)
            }
            else{
                toast.error(res.message)
            }
        }

        setLoading(false);
    }

    const successCallback = async () => {
        setLoading(true);
        const res = await UserServices.cart.order.order(orderData);
        if(res.statusCode === 200){
            user.removeUid();
            if(res.content.user?.token){
                user.setToken(res.content.user.token);
            }
            user.setBasketChange(Math.random().toString(16));
            if(res.content.checkout_url){
                window.open(res.content.checkout_url, '_self');
            }else{
                toast.success(res.message);
                navigate(`/${app.lang}/user/orders`)
            }
        }else if(res.statusCode === 502){
            user.removeUid();
            user.setBasketChange(Math.random().toString(16));
            navigate(`/${app.lang}/order-error`)
        }
        else{
            toast.error(res.message)
        }
        setLoading(false);
    }

    const fetchDeliveryZones = async () => {
        let str = delivery ? `delivery_id=${delivery.id}` : '';
        const res = await UserServices.cart.order.deliveryZones(str);
        
        if(res.statusCode === 200){
            setDeliveryZones(res.content.items);
        }else{
            toast.error(res.message)
        }
    }

    useEffect(() => {
        if(delivery){
            fetchDeliveryZones(); 
        }
    }, [delivery])

  return (
    <div className='checkout'>
        <div className="container">
            <h1>{app.localizationsItems?.checkout_ordering}</h1>
            <FormProvider {...methods} >
                <form onSubmit={methods.handleSubmit(CustomSubmit)}>
                    {loading &&
                        <div className='loader-wrapper-absolute'> <span className="loader"></span> </div>
                    }
                    <div className="row">
                        <div className="col-sm-12 col-md-7 col-lg-7">
                            <DeliveryOption setDelivery={setDelivery}/>
                            {delivery?.slug === "delivery" ?
                                user.token ?
                                    <UserAddress setAddress={setAddress} deliveryZones={deliveryZones}/>
                                    :
                                    <CheckoutAddress address={address} setAddress={setAddress} deliveryZones={deliveryZones}/>
                                :
                            /*delivery?.slug === "self" ?
                                <DeliveryPoints/>   
                                :*/
                                <></>
                            }
                            <PaymentOption/>
                            {!user.token && <UserInfo/>}
                            <OrderComment/>
                        </div>
                        <div className="col-sm-12 col-md-5 col-lg-5">
                            <SummaryCheckout address={address} delivery={delivery} balance={balance} setBalance={setBalance} bonuseUse={bonuseUse} setBonuseUse={setBonuseUse} deliveryZones={deliveryZones} promocode={promocode} setPromocode={setPromocode}/>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
        {codeShow && <LoginCodeModal show={codeShow} setShow={setCodeShow} phone={phone} successCallback={successCallback}/>}
    </div>
  )
})

export default CheckoutPage