import React, { useContext, useEffect, useState } from 'react'
import RecommendedProductCard from '../simple/RecommendedProductCard'
import Slider from 'react-slick';
import UserServices from '../../services/general/userServices';
import { Context } from '../..';

const RecommendedProducts = () => {
    const {user, app} = useContext(Context);
    
    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 3,

        responsive: [{
                breakpoint: 1250,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 886,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    }

    const [items, setItems] = useState();

    const fetchProducts = async () => {
        let str = (app.city?.value && user.uid) ? 
            `uid=${user.uid}&city_id=${app.city?.value}` 
            : (app.city?.value && !user.uid) ?
            `city_id=${app.city?.value}` 
            : (!app.city?.value && user.uid) ?
            `uid=${user.uid}` :
            '';

        const res = await UserServices.cart.suggest(str);

        if(res.statusCode === 200){
            res.content.items && setItems(res.content.items);
        }
    }

    useEffect(() => {
        fetchProducts()
    }, [])

  return (
    items?.length >= 1 &&
    <div className='recommended-wrapper'>
        <h5>{app.localizationsItems?.something_else}</h5>
        <Slider {...settings} className='recommended-slider'>
            {items.map((card, index) =>
                <RecommendedProductCard card={card} key={index}/>
            )}
        </Slider>
    </div>
  )
}

export default RecommendedProducts