import React from 'react'
import StoriesLoading from './StoriesLoading'
import ProductsLoading from './ProductsLoading'
import SkeletonLoading from './SkeletonLoading'
import HeaderLoading from './HeaderLoading'
import FooterLoading from './FooterLoading'

const PageLoading = () => {
  return (
    <>
        <HeaderLoading/>
        <div className='mb-50'>
            <StoriesLoading/>
        </div>
        <div className='mb-50'>
            <ProductsLoading/>
        </div>
        <div className='container'>
            <SkeletonLoading/>
        </div>
        <FooterLoading/>
    </>
  )
}

export default PageLoading