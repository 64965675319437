import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import ChatServices from '../../services/general/chatsServices';
import Utils from '../../services/utils';

const ChatFileModal = ({show, setShow, files, info, successCallback}) => {
    const handleClose = () => setShow(false);
    const [value, setValue] = useState('');

    const handleSend = async () => {
        let formData = new FormData();

        files.forEach(file => {
            formData.append('attachments[]', file);
        });

        formData.append('text', value);

        const res = await ChatServices.messages.send(formData);

        if(res.statusCode === 200){
            successCallback();
        }else{
            toast.error(res.message)
        }
    }

  return (
    <Modal show={show} onHide={handleClose} centered >
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <div className='discount-modal-top'>
                <h3>Отправить файл</h3>
            </div>
            <div>
                <div className='images-flex'>
                    {files?.map((card, index) =>
                        card.type.startsWith('image/') ? 
                            <img 
                                key={index}
                                src={URL.createObjectURL(card)} 
                                alt="" 
                            />
                            : 
                            <div className='file-item' key={index}>
                                <div className='file-icon'>
                                    <i className='icon-bill'></i>
                                </div>
                                <div>
                                    <div className='title'>{card.name}</div>
                                    <div className='size'>{Utils.formatFileSize(card.size)}</div>
                                </div>
                            </div>
                    )}
                </div>
                <div className='images-bottom'>
                    <input type="text" className='chat-file-input' placeholder='Комментарий' value={value} onChange={e => setValue(e.target.value)}/>
                    <div className='chat-file-button' onClick={handleSend}>
                        <div>Отправить</div>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default ChatFileModal