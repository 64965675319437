import React, { useContext } from 'react'
import Slider from 'react-slick';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import FavoriteCard from '../simple/FavoriteCard';

const FavoritesSlider = observer(({items}) => {
    const {app} = useContext(Context);
    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1250,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 996,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 886,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            }
        },
        ]
    }

  return (
    <div className='mb-50 category-item' id={'favorites'}>
        <h2 className='mb-30'>{app.localizationsItems?.wishlist}</h2>
        <Slider {...settings} className='favorites-slider'>
            {items.map((card, index) =>
                <FavoriteCard card={card} key={card.id}/>
            )}
        </Slider>
    </div>
  )
})

export default FavoritesSlider