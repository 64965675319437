import React, { useContext, useState } from 'react'
import AddressCard from '../simple/AddressCard'
import AddAddressModal from '../modals/AddAddressModal';
import useFetchItems from '../../hooks/useFetchItems';
import UserServices from '../../services/general/userServices';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const UserAddress = observer(({setAddress, deliveryZones}) => {
    const {app} = useContext(Context);

    const [show, setShow] = useState();

    const {
        items,
        fetchItems
    } = useFetchItems(UserServices.addresses.index)

  return (
    <div className='box'>
        <div className="flex">
            <h4>{app.localizationsItems?.delivery_address} <span>*</span></h4>
            <div className='main-btn simple-btn' onClick={() => setShow(true)}>
                <i className='icon-plus'></i>
                {app.localizationsItems?.add_address} 
            </div>
        </div>
        <div className="address-grid">
            {items?.items?.map((card, index) =>
                <AddressCard card={card} fetchItems={fetchItems} key={index} setAddress={setAddress} deliveryZones={deliveryZones}/>
            )}
        </div>
        {show && <AddAddressModal show={show} setShow={setShow} fetchItems={fetchItems} deliveryZones={deliveryZones}/>}
    </div>
  )
})

export default UserAddress