import {useCallback, useEffect, useState} from "react";
import { useLocation, useNavigate} from "react-router-dom";
import i18n from "i18next";

const useFetchItems = (method, data) => {
    let query = new URLSearchParams(useLocation().search);
    const history = useNavigate()

    const [items, setItems] = useState();
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState();
    const [params, setParams] = useState(query);
    const [pageCount, setPageCount] = useState();
    const [heading, setHeading] = useState();
    const fetchItems = useCallback(async () => {
        setLoading(true);
        const res = data ? await method(data, params.toString()) : await method(params.toString());
        
        if (res.statusCode === 200) {
            setItems(res.content);
            setCount(res?.content?.total)
            setPageCount(res.content?.total_pages);
            setHeading(res.content?.headings)

        } 

        setLoading(false);
    }, [params, history, method]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchItems();

        const handleLanguageChange = () => {
            fetchItems();
        };

        i18n.on("languageChanged", handleLanguageChange);

        return () => {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, [fetchItems]);

    const handlePageClick = (data) => {
        query.set('page', data.selected + 1);
        setParams(query);
    };

    const setFilterData = (filterString) => {
        let newParams = new URLSearchParams(filterString);
        if (query.get('order')) {
            newParams.set('order', query.get('order'))
        }
        setParams(newParams);
    };

    const setSortType = (value) => {
        query.set('order', value);
        setParams(query);
    };

    return {
        items, loading, pageCount, params, heading, handlePageClick, setFilterData, setSortType, fetchItems, setItems, total: count
    }
};

export default useFetchItems;