import React, { useState } from 'react'

const SkeletonLoading = () => {
    const skeletonItems = Array.from({ length: 12 }, (_, index) => (
        <div className='skeleton-item' key={index}>
            <div className='skeleton-img'></div>
            <div className='skeleton-title'></div>
            <div className='skeleton-text'></div>
            <div className='skeleton-text'></div>
            <div className='skeleton-button'>
                <div className='skeleton-price'></div>
                <div className='skeleton-right'></div>
            </div>
        </div>
      ));

  return (
    <>
        <div className='loading-top2'></div>
        <div className='skeleton-loading'>
            {skeletonItems}
        </div>
        <div className='flare'></div>
    </>
  )
}

export default SkeletonLoading