import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { NavLink, useNavigate} from 'react-router-dom';
import { Context } from '../..';
import ActiveOrders from '../simple/ActiveOrders';

const Orders = observer(() => {
    const {app} = useContext(Context);
    const navigate = useNavigate();

  return (
    <div className='profile-dashboard'>
        <div className='button-back'>
            <i className='icon-chevron-left' onClick={() => navigate(`/${app.lang}/mob-user`)}></i>
            <h3>{app.localizationsItems?.my_orders}</h3>
        </div>
        <ActiveOrders/>
    </div>
  )
})

export default Orders