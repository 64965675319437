import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const SelectCityModal = observer(({show, setShow, cities}) => {
    const {app, user} = useContext(Context);

    const {handleSubmit, register, setValue} = useForm();

    useEffect(() => {
        if(localStorage.getItem('city')){
            setValue('city', localStorage.getItem('city'))
        }
    }, [])

    const handleClose = () => {
        setShow(false);
    }

    const CustomSubmit = (data) => {
        const selectedCity = JSON.parse(data.city);
        app.setCity(selectedCity)
        setShow(false)
    };

  return (
    <Modal show={show} onHide={handleClose} centered className='city-modal'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <form onSubmit={handleSubmit(CustomSubmit)}>
                <h3>{app.localizationsItems?.select_city}</h3>
                <div className='city-modal-content'>
                    {app.allCities?.map((card, index) =>
                        <label className="form-check-label" key={index}>
                            <input className="form-check-input" required type="radio" 
                                {...register('city', {required: true})}
                                value={JSON.stringify({ value: card.value, label: card.label, location: card.location })}
                            />
                            {card.label}
                        </label>
                    )}
                </div>
                <button className='main-btn'>{app.localizationsItems?.save}</button>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default SelectCityModal