import React, { useContext, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook';
import AppServices from '../../services/general/appServices';
import { toast } from 'react-toastify';

const DeliveUserItems = observer(({CustomSubmit, addressValue, setAddressValue, setLocation}) => {
    const {app} = useContext(Context);

    const {register} = useFormContext();

    const dropdown = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdown, false);

    const [typingTimeout, setTypingTimeout] = useState(0);

    const [availableAddresses, setAvailableAddresses] = useState();

    const handleChange = (e) => {
        setIsActive(true);
        setAddressValue(e.target.value);

        clearTimeout(typingTimeout);

        setTypingTimeout(setTimeout(async () => {
            const res = await AppServices.suggest(e.target.value);

            if (res.statusCode === 200) {
                setAvailableAddresses(res.content?.items);
            }
        }, 700));
    }

    const handleSelectLocation = async (el) => {
        setIsActive(false);
        setAddressValue(el.title);
        const res = await AppServices.search(el.title);

        if(res.statusCode === 200){
            setLocation(res.content.geo)
        }else{
            toast.error(res.message)
        }
    }

  return (
    <>
        <h3 className='mob-none'>{app.localizationsItems?.new_address}</h3>
        <div className='search-values-container'>
            <input 
                value={addressValue} 
                onChange={e => handleChange(e)} 
                placeholder={app.localizationsItems?.address} 
                className="input input-center"
            ></input>
            {isActive && <div className='search-values-items' ref={dropdown}>
                {availableAddresses?.map((el, index) =>
                    <div className='search-values-item' key={index} onClick={e => handleSelectLocation(el)}>
                        <div>{el.title}</div>
                        <div className='search-values-item-sub'>{el.subtitle}</div>
                    </div>
                )}
            </div>}
        </div>
        <div className="inputs-grid">
            <input type="text" className="input" {...register('entrance')} placeholder={app.localizationsItems?.entrance}/>
            <input type="text" className="input" {...register('floor')} placeholder={app.localizationsItems?.floor}/>
            <input type="text" className="input" {...register('apartment')} placeholder={app.localizationsItems?.apartment}/>
            <input type="text" className="input" {...register('code')} placeholder={app.localizationsItems?.code_domofon}/>
        </div>
        <div onClick={CustomSubmit} className='main-btn'>{app.localizationsItems?.save}</div> 
    </>
  )
})

export default DeliveUserItems