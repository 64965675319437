import React from 'react'

const CartDevices = ({setDeviceCount, deviceCount}) => {

    const handleIncrement = () => {
        setDeviceCount(deviceCount + 1)
    }

    const handleDecrement = () => {
        if(deviceCount > 0){
            setDeviceCount(deviceCount - 1)
        }
    }

  return (
    <div className='cart-devices'>
        <div className='cart-devices-title'>
            <i className='icon-devices'></i>
            Приборы
        </div>
        <div className='input-count'>
            <div className='input-btn' onClick={handleDecrement}>
                <i className='icon-minus'></i>
            </div>
            <div className='value'>{deviceCount}</div>
            <div className='input-btn' onClick={handleIncrement}>
                <i className='icon-plus'></i>
            </div>
        </div>
    </div>
  )
}

export default CartDevices