import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { Context } from '../..';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const DashboardEdit = observer(() => {
    const {app, user} = useContext(Context);

    const {register, control, setValue, handleSubmit, formState: {errors}} = useForm();

    const navigate = useNavigate();

    useEffect(() => {
        if(user.user){
            for (const [key, value] of Object.entries(user.user)) {
                user.user[key] !== null && setValue(key, String(value));
            }
        }
    }, [user.user])

    const CustomSubmit = async (data) => {
        const res = await UserServices.profile.update(data);

        if (res.statusCode === 200) {
            user.setUser(res.content)
            toast.success(`${app.localizationsItems?.success_update_profile}`)
        }else{
            toast.error(res.message)
        }
    }

  return (
    <form className='profile-dashboard' onSubmit={handleSubmit(CustomSubmit)}>
        <div className='button-back'>
            <i className='icon-chevron-left' onClick={() => navigate(`/${app.lang}/user/dashboard`)}></i>
            <h3>{app.localizationsItems?.profile_edit}</h3>
        </div>
        {user.user &&
            <div className='profile-dashboard-wrapper'>
                <div className='form-label'>
                    <label>{app.localizationsItems?.name}</label>
                    <input type="text" className="input" required {...register('name')} placeholder={app.localizationsItems?.name}/>
                </div>
                <div className='form-label'>
                    <label>{app.localizationsItems?.phone}</label>
                        <Controller defaultValue="" name="phone" control={control}
                            render={({field: {onChange, value}}) => (
                                <ReactInputMask onChange={onChange} value={value}
                                        mask="+7(999)999-99-99" maskChar="_">
                                        {
                                            inputProps => (
                                                <input type="tel" className="input" placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                            )
                                        }
                                </ReactInputMask>
                            )}
                            rules={{pattern: {
                                value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                            }}}
                    />
                </div>
                <div className='form-label'>
                    <label>{app.localizationsItems?.email}</label>
                    <input type="email" className="input" required {...register('email')} placeholder={app.localizationsItems?.email}/>
                </div> 
                <div className='form-label'>
                    <label>{app.localizationsItems?.birthday}</label>
                    <input type="date" className="input" {...register('date_of_birth')} autoComplete="off" placeholder={app.localizationsItems?.name}/>
                </div> 
                <div className='form-label'>
                    <div>{app.localizationsItems?.gender}</div>
                    <div className="form-label-radio">
                        <label className="form-check-label">
                            <input className="form-check-input" type="radio" value={'female'}
                            {...register('gender')} autoComplete="off"
                            />
                            {app.localizationsItems?.female}
                        </label>
                        <label className="form-check-label">
                            <input className="form-check-input" type="radio" value={'male'}
                                {...register('gender')} autoComplete="off"
                            />
                            {app.localizationsItems?.male}
                        </label>
                    </div>
                </div> 
                <button className='main-btn'>{app.localizationsItems?.save}</button>
            </div>
        }
    </form>
  )
})

export default DashboardEdit