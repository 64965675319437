import React, { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';

const AboutUs = observer(() => {
    const {app} = useContext(Context);
    const navigate = useNavigate();

    const {
        items,
    } = useFetchItems(AppServices.menu, 'top-menu')

  return (
    <div>
        <div className='button-back'>
            <i className='icon-chevron-left' onClick={() => navigate(`/${app.lang}/mob-user`)}></i>
            <h3>{app.localizationsItems?.about_us}</h3>
        </div>
        <div className="tab-links">
            {items?.map((item, index) =>
                <div className={'tab-links-item pd-left'} key={index}>
                    <NavLink to={item.value} target={item.type}>
                        {item.title}
                    </NavLink>
                </div>
            )}
        </div>
    </div>
  )
})

export default AboutUs