import React, { useContext, useEffect, useState } from 'react'
import Utils from '../../services/utils'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';
import ProductViewModal from '../modals/ProductViewModal';
import UserServices from '../../services/general/userServices';
import img from "../../images/placeholder.png"
import ProductCardButton from './ProductCardButton';

const ProductCard = observer(({card, setCartOpen}) => {
    const {app, user} = useContext(Context);

    const [show, setShow] = useState();

    const [isFavorite, setIsFavorite] = useState();

    useEffect(() => {
        if(user.favorites.find(it => it ===card.id)){
            setIsFavorite(true);
        }else{
            setIsFavorite(false);
        }
    }, [user.favorites, user.wishlistCountChange])

    const addToWishlist = async (e) => {
        e.stopPropagation();
        let temp = isFavorite;
        setIsFavorite(!isFavorite);
        const newData = {
            uid: user.wishlistUid ? user.wishlistUid : null,
            product_id: card.id
        }

        const res = await UserServices.favorites.toggle(newData);

        if (res.statusCode === 200) {
            if(!user.wishlistUid){
                user.setWishlistUid(res.content.uid);
            }
            user.setWishlistCountChange(Math.random().toString(16))
        }else{
            setIsFavorite(temp)
        }
    }

    const [showRecomended, setShowRecomended] = useState();
    const [recomendedSlug, setRecomendedSlug] = useState();

    const openProduct = (slug) => {
        setRecomendedSlug(slug);
        setShowRecomended(true);
        setShow(false);
    }

  return (
    <div className='product-card'>
        <div className='card-relative' onClick={() => setShow(true)}>
            <span className='to-wishlist'  onClick={e =>addToWishlist(e)}>
                <i className={isFavorite ? 'icon-like active' : 'icon-heart'}></i>
            </span>
            <img src={card.image ? card.image : img} alt={card.title} title={card.title}
                width={266}
                height={225}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
                loading='lazy'
            />
            <div className='modal-states'>
                {card.states?.map((it, index) =>
                    <div className="product-state" style={{backgroundColor: `${it.subtitle}`}} key={index}>
                        <label>{it.name}</label>
                    </div>
                )}
                {card.bonus && <div className="product-bonus">
                    <label>{card.bonus.number}{card.bonus.type === 1 ? '%' : ''} Б</label>
                </div>}
            </div>
        </div>
        <div className='product-flex'>
            <div className='product-card-wrapper' onClick={() => setShow(true)}>
                <h4>{card.title}</h4>
                <div className='subtitle'>{card.subtitle}</div>
                <div className='content' dangerouslySetInnerHTML={{__html: card.description}}></div>
            </div>
            <div className='product-card-content'>
                <h4>
                    {card.old_price && <span className='old-price'>{Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</span>}
                    <span className='price'>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency} </span>
                </h4>
                <ProductCardButton info={card} setShow={setShow}/>
            </div>
        </div>
        {show && <ProductViewModal show={show} setShow={setShow} slug={card.slug} setCartOpen={setCartOpen} openProduct={openProduct}/>}
        {showRecomended && <ProductViewModal show={showRecomended} setShow={setShowRecomended} slug={recomendedSlug} setCartOpen={setCartOpen}/>}
    </div>
  )
})

export default ProductCard