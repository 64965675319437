import http from "./http-common"
import {getData, postData} from "../handlers";

export default class NotificationServices {
    static index = (queryString) => {
        return getData(http, `/app/user/notifications${queryString ? `?${queryString}` : ""}`);
    }
    static setRead = (data) => {
        return postData(http, `/app/user/notifications/setRead`, data);
    }
}