import React, { useContext, useState } from 'react'
import UserAddAddressModal from '../modals/UserAddAddressModal';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const CheckoutAddress = observer(({address, setAddress}) => {
    const {app} = useContext(Context);
    const [show, setShow] = useState(true);

  return (
    <div className='box'>
        <div className="flex">
            <h4>{app.localizationsItems?.delivery_address} <span>*</span></h4>
            <div className='main-btn simple-btn' onClick={() => setShow(true)}>
                {app.localizationsItems?.choose_another}
            </div>
        </div>
        {address && <div className="address-grid">
            <div className='delivery-point-card2'>
                <p>{address.full_address}</p>
                {address.json_address && <div className="address-grid">
                    <span>{app.localizationsItems?.entrance}: {address.json_address.entrance}</span>
                    <span>{app.localizationsItems?.floor}: {address.json_address.floor}</span>
                    <span>{app.localizationsItems?.apartment}: {address.json_address.apartment}</span>
                    <span>{app.localizationsItems?.code_domofon}: {address.json_address.code}</span>
                </div>}
            </div>
        </div>}
        {show && <UserAddAddressModal show={show} setShow={setShow} setNewAddress={setAddress}/>}
    </div>
  )
})

export default CheckoutAddress