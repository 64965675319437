import React from 'react'
import BottomBar from '../simple/BottomBar'
import FooterMenu from '../simple/FooterMenu'
import ToTopButton from '../simple/ToTopButton'

const Footer = () => {
  return (
    <footer>
        <FooterMenu/>
        <BottomBar/>
        <ToTopButton/>
    </footer>
  )
}

export default Footer