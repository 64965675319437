import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import AuthServices from '../../services/general/authServices';

const CheckCodeModal = observer(({
    show,
    setShow, 
    email,
    setUpdatePasswordModal,
    setCode
}) => {
    const {app} = useContext(Context);

    const [loading, setLoading] = useState();
    
    const handleClose = () => setShow(false);

    const {handleSubmit, register, getValues, control, formState: {errors}} = useForm();

    const CustomSubmit = async () => {
        setLoading(true);
        let newData = {
            email: email,
            code: getValues('code')
        }

        const res = await AuthServices.checkCode(newData);

        if (res.statusCode === 200) {
            setShow(false);
            setCode(newData.code)
            setUpdatePasswordModal(true);
        }else{
            toast.error(res.message);
        }
        setLoading(false);
    }

  return (
        <Modal show={show} onHide={handleClose} centered className='login-modal-container'>
            <div className='close' onClick={handleClose}>
                <i className='icon-close'></i>
            </div>
            <Modal.Body>
                <form className='login-modal' onSubmit={handleSubmit(CustomSubmit)}>
                    {loading &&
                        <div className='loader-wrapper-absolute'> <span className="loader"></span> </div>
                    }
                    <h2>{app.localizationsItems?.recovery_password}</h2>
                    <p>{app.localizationsItems?.forgot_password_content}</p>
                    <div className='form-label'>
                        <label>{app.localizationsItems?.enter_code}*</label>
                        <input type="text" className='input' placeholder={app.localizationsItems?.enter_code} {...register('code')} required/>
                    </div>
                    <div className='main-btn mb-15' onClick={CustomSubmit}>{app.localizationsItems?.send_code}</div>
                </form>
            </Modal.Body>
        </Modal>
  )
})

export default CheckCodeModal