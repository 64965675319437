import React, { useContext, useState } from 'react'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';
import CartPaymentModal from '../modals/CartPaymentModal';
import Utils from '../../services/utils';
import PromocodeServices from '../../services/general/promocodeServices';
import { toast } from 'react-toastify';

const CartPayment = observer(({bonusUse, setBonusUse, promocode, setPromocode, total, delivery, setPromocodeInfo, payment, setPayment, promocodeInfo}) => {
    const {app, user} = useContext(Context);

    const [paymentModal, setPaymentModal] = useState();

    const handlePromocode = async () => {
        if(promocode){
            let str = user.address?.delivery_id ? `price=${total}&delivery_id=${user.address?.delivery_id}` : `price=${total}`
            const res = await PromocodeServices.check(promocode, str);

            if(res.statusCode === 200){
                setPromocodeInfo(res.content)
                toast.success(app.localizationsItems?.promocode_success)
            }else{
                setPromocodeInfo(null)
                toast.error(res.message)
            }
            /*if(res.statusCode === 200){

                if(res.content.type.slug === "min_price_additional_product"){
                    setPromoProduct(res.content.products);
                    setDeliveryPrice(null)
                    setPromoDelivery(false)
                    setPromoDiscount(null)
                }else if(res.content.type.slug === "min_price_discount"){
                    if(res.content.value_type === 1){
                        setPromoDiscount(Math.floor(Number(res.content.value) * total/ 100))
                    }else if(res.content.value_type === 2){
                        setPromoDiscount(Number(res.content.value))
                    }
                    setPromoProduct(null)
                    setPromoDelivery(false)
                }else if(res.content.type.slug === "delivery"){
                    setDeliveryPrice(null)
                    setPromoProduct(null)
                    setPromoDelivery(true)
                    setPromoDiscount(null)
                }
            }else{
                setPromoProduct(null)
                setPromoDiscount(null)
                setPromoDelivery(false)
                toast.error(res.message)
            }*/
        }
    }

    const cancelPromocode = () => {
        setPromocode('');
        setPromocodeInfo(null)
    }

  return (
    <div className='cart-delivery'>
        <div className='cart-delivery-item'>
            <div className='cart-delivery-item-title'>{app.localizationsItems?.payment}</div>
            <div className='cart-delivery-item-content' onClick={()=>setPaymentModal(true)}>{payment?.payment ? payment.payment : app.localizationsItems?.select_payment} <i className='icon-chevron-right'></i></div>
        </div>
        {user.balance ? 
            <div className='cart-delivery-item'>
                <div className='cart-delivery-item-title'>{app.localizationsItems?.spend_bonuses}</div>
                <div className='cart-delivery-item-content'>{Utils.numberWithSpaces(user.balance)} ₸  {app.localizationsItems?.bonuses_accumulated}
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" value={bonusUse} onChange={e => setBonusUse(!bonusUse)}/>
                    </div>
                </div>
            </div>
            :
            <></>
        }
        <div className='cart-delivery-item'>
            <div className='cart-delivery-item-title'>{app.localizationsItems?.promocode}</div>
            <div className='input-flex'>
                <input type="text" placeholder={app.localizationsItems?.promocode} className='input' value={promocode} onChange={e => setPromocode(e.target.value)}/>
                {promocodeInfo ?
                    <div className='main-btn btn-gray' onClick={cancelPromocode}>{app.localizationsItems?.cancel}</div>
                    :
                    <div className='main-btn' onClick={handlePromocode}>{app.localizationsItems?.apply}</div>
                }
            </div>
        </div>
        {paymentModal && <CartPaymentModal show={paymentModal} setShow={setPaymentModal} setPayment={setPayment}/>}
    </div>
  )
})

export default CartPayment