import React, { useState } from 'react'
import NotificationServices from '../../services/general/notificationsServices'
import { useNavigate } from 'react-router-dom'

const NotificationCard = ({card}) => {
    const [read, setRead] = useState(card.read)

    const navigate = useNavigate();

    const handleRead = async () => {
        setRead(true)
        const res = await NotificationServices.setRead({notification_id: card.id});

        if(res.statusCode === 200){

        }else{
            setRead(false)
        }
        if(card.type === "order" && card.value){
            navigate(`/ru/user/orders/${card.value}`)
        }
    }

  return (
    <div className='notification-card' onClick={handleRead}>
        <div className='notification-card-title'>{card.title}
            {read ? <></> : <div className='read'></div>}
        </div>
        <div className='notification-card-title'>{card.message}</div>
        <div className='notification-card-date'>{card.created_at}</div>
    </div>
  )
}

export default NotificationCard