import Login from "../pages/auth/Login";
import CheckoutPage from "../pages/checkout/CheckoutPage";
import OrderError from "../pages/checkout/OrderError";
import OrderSuccess from "../pages/checkout/OrderSuccess";
import MainPage from "../pages/main/MainPage";
import PolitikaKonfidentsialnosti from "../pages/sample/PolitikaKonfidentsialnosti";
import PublichnayaOferta from "../pages/sample/PublichnayaOferta";
import QrMenu from "../pages/sample/QrMenu";
import SamplePage from "../pages/sample/SamplePage";
import MobProfile from "../pages/user/MobProfile";
import Profile from "../pages/user/Profile";
import { CHECKOUT_PAGE_ROUTE, LOGIN_PAGE_ROUTE, MAIN_PAGE_ROUTE, MOB_USER_PAGE_ROUTE, ORDER_ERROR_PAGE_ROUTE, ORDER_SUCCESS_PAGE_ROUTE, POLITIKA_CONF, PUBLICHNAYA_OFERTA, QR_MENU, SAMPLE_PAGE_SOUTE, USER_PAGE_ROUTE } from "./Constants";

export const publicRoutes = [
    {
        path: '',
        Component: MainPage
    },
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    {
        path: CHECKOUT_PAGE_ROUTE,
        Component: CheckoutPage
    },
    {
        path: USER_PAGE_ROUTE,
        Component: Profile
    },
    {
        path: LOGIN_PAGE_ROUTE,
        Component: Login
    },
    {
        path: SAMPLE_PAGE_SOUTE,
        Component: SamplePage
    },
    {
        path: MOB_USER_PAGE_ROUTE,
        Component: MobProfile
    },
    {
        path:POLITIKA_CONF,
        Component: PolitikaKonfidentsialnosti
    },
    {
        path:PUBLICHNAYA_OFERTA,
        Component: PublichnayaOferta
    },
    {
        path:QR_MENU,
        Component: QrMenu
    },
    {
        path: ORDER_ERROR_PAGE_ROUTE,
        Component: OrderError
    },
    {
        path: ORDER_SUCCESS_PAGE_ROUTE,
        Component: OrderSuccess
    },
]
