import {useCallback, useEffect, useState} from "react";
import { toast } from "react-toastify";

const useFetchInfo = (method, id, additionalData, isCallback) => {
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState();

    const fetchInfo = useCallback(async () => {
        setLoading(true);
        
        const res = additionalData ? await method(id, additionalData) : await method(id);

        if (res.statusCode === 200) {
            setInfo(res.content)
        } else {
            toast.error(res.message)
        }

        setLoading(false);
    }, [id, method, isCallback])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchInfo()
    }, [fetchInfo]);

    return {
        info, loading, fetchInfo
    }
}

export default useFetchInfo