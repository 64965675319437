import React, { useContext, useEffect, useState } from 'react'
import CheckoutProductCard from '../simple/CheckoutProductCard'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import Utils from '../../services/utils'
import UserServices from '../../services/general/userServices'
import PromocodeServices from '../../services/general/promocodeServices'
import { toast } from 'react-toastify'

const SummaryCheckout = observer(({address, delivery, balance, setBalance, bonuseUse, setBonuseUse, deliveryZones, promocode, setPromocode}) => {
    const {app, user} = useContext(Context);

    const [total, setTotal] = useState(0);
    const [bonuses, setBonuses] = useState();
    const [discount, setDiscount] = useState();
    const [subTotal, setSubTotal] = useState(0);

    const [deliveryPrice, setDeliveryPrice] = useState();
    const [maxDeliveryPrice, setMaxDeliveryPrice] = useState();

    const [promoProduct, setPromoProduct] = useState();
    const [promoDiscount, setPromoDiscount] = useState();
    const [promoDelivery, setPromoDelivery] = useState();

    useEffect(() => {
        if(user.cartProducts){
            let totalTemp = 0;
            let subtotaltotalTemp = 0;
            let totalBonuses = 0;
            for(let i = 0; i < user.cartProducts.length; i++){
                totalTemp += Number(user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
                subtotaltotalTemp += Number(user.cartProducts[i]?.old_price ?  user.cartProducts[i]?.old_price : user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
                if(user.cartProducts[i].bonus?.type == 1){
                    totalBonuses += (Math.floor((Number(user.cartProducts[i].price) * Number(user.cartProducts[i].bonus.number))/100) * Number(user.cartProducts[i].count))
                }else if(user.cartProducts[i].bonus){
                    totalBonuses += (Math.floor(Number(user.cartProducts[i].bonus?.number) * Number(user.cartProducts[i]?.count)))
                }
            }
            setTotal(totalTemp);
            setSubTotal(subtotaltotalTemp);

            let usedTotalBonuses = 0;
            if(bonuseUse){
                for(let i =0; i< user.cartProducts.length; i++) {
                    let summ = (Number(user.cartProducts[i].price) * Number(user.cartProducts[i].count));
                    let procent = (summ * 100) / totalTemp;
                    let dif = (balance * procent) / 100;
                    if(user.cartProducts[i].bonus?.type == 1){
                        usedTotalBonuses += Math.floor((Number(summ - dif) * user.cartProducts[i].bonus.number)/100);
                    }else if(user.cartProducts[i].bonus){
                        usedTotalBonuses += Math.floor(Number(user.cartProducts[i].bonus?.number) * Number(user.cartProducts[i]?.count))
                    }
                }
                setBonuses(usedTotalBonuses)
            }else if(totalBonuses && totalBonuses > 0){
                setBonuses(totalBonuses);
            }
            if(totalTemp !== subtotaltotalTemp){
                setDiscount(Number(subtotaltotalTemp)-Number(totalTemp))
            }
        }
    }, [user.cartProducts, bonuseUse])

    useEffect(() => {
        if(address?.json_address && deliveryZones){
                let values = null;
                for(let i = 0; i<deliveryZones.length; i++){
                    const result = Utils.isMarkerInsidePolygon([address.json_address.lat, address.json_address.lng], deliveryZones[i].zones);
                    if(result){
                        values = deliveryZones[i].value
                    }
                }

                if(values?.length >= 1){
                    for(let i = 0; i<values.length; i++){
                        if(total > values[i].price_from && (total < values[i].price_to || (values[i].price_to === 0 || !values[i].price_to))){
                            setDeliveryPrice(values[i].delivery_price)
                        }
                    }
                    const lastItem = values[values.length-1];
                    lastItem.delivery_price <= 0 && setMaxDeliveryPrice(lastItem.price_from)
                }else{
                    setDeliveryPrice(null)
                    setMaxDeliveryPrice(null)
                }
        }
    }, [address, deliveryZones, total])

    const handleUseBonuses = () => {
        setBonuseUse(!bonuseUse);
    }

    const userProfile = async () => {
        const res = await UserServices.profile.index();
        if(res.statusCode === 200){
            setBalance(res.content.balance);
        }
    }

    useEffect(() => {
        if(user.token){
            userProfile();
        }
    }, [user.token])

    const handlePromocode = async () => {
        if(promocode){
            let str = delivery ? `price=${total}&delivery_id=${delivery.id}` : `price=${total}`
            const res = await PromocodeServices.check(promocode, str);
            if(res.statusCode === 200){
                if(res.content.type.slug === "min_price_additional_product"){
                    setPromoProduct(res.content.products);
                    setDeliveryPrice(null)
                    setPromoDelivery(false)
                    setPromoDiscount(null)
                }else if(res.content.type.slug === "min_price_discount"){
                    if(res.content.value_type === 1){
                        setPromoDiscount(Math.floor(Number(res.content.value) * total/ 100))
                    }else if(res.content.value_type === 2){
                        setPromoDiscount(Number(res.content.value))
                    }
                    setPromoProduct(null)
                    setPromoDelivery(false)
                }else if(res.content.type.slug === "delivery"){
                    setDeliveryPrice(null)
                    setPromoProduct(null)
                    setPromoDelivery(true)
                    setPromoDiscount(null)
                }
            }else{
                setPromoProduct(null)
                setPromoDiscount(null)
                setPromoDelivery(false)
                toast.error(res.message)
            }
        }
    }

    const calculateTotalWithDeliveryAndBonuses = () => {
        let totalWithDelivery = total;
    
        if (delivery?.slug !== "self" && deliveryPrice) {
            totalWithDelivery += Number(deliveryPrice);
    
            if (bonuseUse) {
                totalWithDelivery -= Number(balance);
            }
            if(promoDiscount){
                totalWithDelivery -= Number(promoDiscount);
            }
        } else if (bonuseUse) {
            totalWithDelivery -= Number(balance);

            if(promoDiscount){
                totalWithDelivery -= Number(promoDiscount);
            }
        } else if (promoDiscount) {
            totalWithDelivery -= Number(promoDiscount);
        }
    
        return totalWithDelivery;
    };

  return (
    <div>
        <div className='box'>
            <h4>{app.localizationsItems?.your_order}</h4>
            {user.cartProducts?.map((card, index) =>
                <CheckoutProductCard card={card} key={index}/>
            )}
            {promoProduct?.map((card, index) =>
                <div className='checkout-product-card' key={index}>
                    <div>
                        <h6>{card.title} х {card.count}</h6>
                        <div className='success'>{app.localizationsItems?.promo_product_content}</div>
                    </div>
                    <h6 className='discount'>{app.localizationsItems?.free}</h6>
                </div>
            )}
            {balance ? <div className='list-item bonus-switcher'>
                <div className='text'>
                    <div>{app.localizationsItems?.spend_bonuses}</div>
                    <span>{Utils.numberWithSpaces(balance)} {app.localizationsItems?.bonuses_accumulated}</span>
                </div>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" value={bonuseUse} onChange={ e => handleUseBonuses(e)}/>
                </div>
            </div> : <></>}
            <div className='flex'>
                <h5>{app.localizationsItems?.sub_total}</h5> <h5>{Utils.numberWithSpaces(subTotal)} {app.localizationsItems?.currency}</h5>
            </div>
            {(deliveryPrice || bonuseUse || discount || promoDiscount || promoDelivery) ? <div className='cart-summary-wrapper summary-wraper'>
                {(delivery?.slug !== "self" && deliveryPrice && !promoDelivery) && <div className='list-item'>{app.localizationsItems?.delivery}<span>{Utils.numberWithSpaces(deliveryPrice)} {app.localizationsItems?.currency}</span></div>}
                {bonuseUse && <div className='list-item'>{app.localizationsItems?.used_bonuses}<span>- {Utils.numberWithSpaces(balance)} {app.localizationsItems?.currency}</span></div>}
                {discount && <div className='list-item'>{app.localizationsItems?.discount_price}<span className='discount'>- {Utils.numberWithSpaces(discount)} {app.localizationsItems?.currency}</span></div>}
                {promoDiscount && <div className='list-item'>{app.localizationsItems?.promo_discount_content}<span className='discount'>- {Utils.numberWithSpaces(promoDiscount)} {app.localizationsItems?.currency}</span></div>}
                {promoDelivery && <div className='list-item'>{app.localizationsItems?.promo_delivery_content}<span className='discount'>{app.localizationsItems?.free}</span></div>}
            </div> : <></>}
            <div className='input-flex'>
                <input type="text" value={promocode} onChange={e => setPromocode(e.target.value)} placeholder={app.localizationsItems?.promocode} className='input'/>
                <div className='main-btn btn-outline' onClick={handlePromocode}>{app.localizationsItems?.apply}</div>
            </div>
            <div className='checkout-bottom'>
                <div className='flex'>
                    <h3>{app.localizationsItems?.total}</h3> 
                    <h3>
                        {Utils.numberWithSpaces(calculateTotalWithDeliveryAndBonuses())} {app.localizationsItems?.currency} 
                    </h3>
                </div>
                {bonuses && <div className='list-item'>{app.localizationsItems?.get_bonuses}<span className='product-bonus'>+ {Utils.numberWithSpaces(bonuses)} Б</span></div>}
                <button className='main-btn blink-button'>{app.localizationsItems?.checkout_order} <span className='flare'></span></button>
            </div>
        </div>
        {(bonuses || (maxDeliveryPrice && Number(maxDeliveryPrice) > Number(total))) &&
        <div className="box">
            {bonuses && <div className='bottom-checkount'>
                <i className='icon-gift'></i>
                {app.localizationsItems?.get_bonuses_content}
            </div>}
            {maxDeliveryPrice && <div className='bottom-checkount'>
                <i className='icon-delivery'></i>
                {app.localizationsItems?.free_delivery_amount} -
                <span>{Utils.numberWithSpaces(Number(maxDeliveryPrice) - Number(total))} {app.localizationsItems?.currency}</span>
            </div>}
        </div>}
    </div>
  )
})

export default SummaryCheckout