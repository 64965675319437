import React from 'react'

const ProductFeatures = ({info}) => {
  return (
    <div className='product-features'>
        {info.features.map((feature, index) =>
            <div className='product-features-item' key={index}>
                <div>{feature.title}</div>
                <div className='value'>{feature.value}</div>
            </div>
        )}
    </div>
  )
}

export default ProductFeatures