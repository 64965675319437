import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import AuthServices from '../../services/general/authServices';

const RegistrationModal = observer(({show, setShow}) => {
    const {app} = useContext(Context);

    const [loading, setLoading] = useState();
    
    const handleClose = () => setShow(false);

    const {handleSubmit, register, getValues, control, formState: {errors}} = useForm();

    const CustomSubmit = async () => {
        setLoading(true);
        let newData = {
            name: getValues('name'),
            email: getValues('email'),
            password: getValues('password'),
            password_confirmation: getValues('password_confirmation'),
        }

        const res = await AuthServices.register(newData);

        if (res.statusCode === 200) {
            toast.success(res.message);
            setShow(false);
        }else{
            toast.error(res.message);
        }
        setLoading(false);
    }

  return (
    <Modal show={show} onHide={handleClose} centered className='login-modal-container'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <form className='login-modal' onSubmit={handleSubmit(CustomSubmit)}>
                {loading &&
                    <div className='loader-wrapper-absolute'> <span className="loader"></span> </div>
                }
                <h2>{app.localizationsItems?.registration}</h2>
                <p>{app.localizationsItems?.registration_content}</p>
                <div className='form-label'>
                    <label>{app.localizationsItems?.name}*</label>
                    <input type="text" className='input' placeholder={app.localizationsItems?.name} {...register('name')} required/>
                </div>
                <div className='form-label'>
                    <label>{app.localizationsItems?.email}*</label>
                    <input type="email" className='input' placeholder={app.localizationsItems?.email} {...register('email')} required/>
                </div>
                <div className='form-label'>
                    <label>{app.localizationsItems?.password}*</label>
                    <input type="password" className='input' placeholder={app.localizationsItems?.password} {...register('password')} required/>
                </div>
                <div className='form-label'>
                    <label>{app.localizationsItems?.confirm_password}*</label>
                    <input type="password" className='input' placeholder={app.localizationsItems?.confirm_password} {...register('password_confirmation')} required/>
                </div>
                <div className='main-btn mb-15' onClick={CustomSubmit}>{app.localizationsItems?.registration}</div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default RegistrationModal