import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';
import Utils from '../../services/utils';

const OrderCard2 = observer(({card}) => {
    const {app} = useContext(Context);

  return (
    <NavLink to={`/${app.lang}/user/orders/${card.id}`}>
        <div className='order-card'>
            <div className="flex">
                {card.status && <div className='order-card-state' style={{backgroundColor: card.status.color}}>{card.status.name}</div>}
                {/*active && <button className='main-btn accent-btn btn-sm'>Повторить заказ</button>*/}
            </div>
            <div className='order-wrapper'>
                <div>
                    <div className='order-text'>{card.address?.full_address}</div>
                    <div>{app.localizationsItems?.order}: №{card.number}</div>
                    <div>{card.created_at}</div>
                </div>
                <div>
                    <div className='order-price'>{Utils.numberWithSpaces(card.total_price)} {app.localizationsItems?.currency}</div>
                    {card.products &&
                        <div className='order-products'>
                            {card.products.slice(0, 2).map((el, index) =>
                                <img src={el.image} alt="" key={index}/>
                            )}
                            {card.products.length >= 3 && <div className='order-card-box'>+{Number(card.products.length) - 2}</div>}
                        </div>
                    }
                </div>
            </div>
        </div>
    </NavLink>
  )
})

export default OrderCard2