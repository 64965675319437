import http from "./http-common"
import {postData} from "../handlers";

export default class AuthServices {
    static logIn = (data) => {
        return postData(http, '/app/auth/login', data);
    }

    static register = (data) => {
        return postData(http, '/app/auth/register', data);
    }

    static logOut = () => {
        return postData(http, '/app/auth/logout');
    }

    static sendCode = (data) => {
        return postData(http, '/app/auth/sendCode', data);
    }

    static checkCode = (data) => {
        return postData(http, '/app/auth/checkCode', data);
    }

    static updatePassword = (data) => {
        return postData(http, '/app/auth/updatePassword', data);
    }

    static authWithPhone = (data) => {
        return postData(http, '/app/auth/authWithPhone', data);
    }
}