import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..';
import appStore from "../../images/appstore1.png"
import playMarket from "../../images/playstore1.png"

const AppAdvertisingBanner = observer(() => {
    const {app} = useContext(Context);

    const [appStoreLink, setAppStoreLink] = useState();
    const [googlePlayLink, setGooglePlayLink] = useState();
    
    useEffect(() => {
        if(app.settings){
            setAppStoreLink(app.settings.find(it => it.type === "app-store-link")?.value);
            setGooglePlayLink(app.settings.find(it => it.type === "google-play-link")?.value);
        }
    }, [app.settings])

  return (
    (appStoreLink || googlePlayLink) &&
    <div className='app-banner'>
        <div className='container'>
            <div className='app-baner-content'>
                <h2>{app.localizationsItems?.application_banner_content}</h2>
                <div className="description-flex">
                    {appStoreLink && <a href={appStoreLink} target="_blank" rel="noopener">
                        <img src={appStore} alt="" loading="lazy"/>
                    </a>}
                    {googlePlayLink && <a href={googlePlayLink} target="_blank" rel="noopener">
                        <img src={playMarket} alt="" loading="lazy"/>
                    </a>}
                </div>
            </div>
        </div>
    </div>
  )
})

export default AppAdvertisingBanner