import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import Utils from '../../services/utils';
import CartModal from '../modals/CartModal';

const CartButton = observer(() => {
    const {app, user} = useContext(Context);
    const [total, setTotal] = useState();

    const [show, setShow] = useState();

    useEffect(() => {
        if(user.cartProducts){
            let temp = 0;
            for(let i = 0; i < user.cartProducts.length; i++){
                temp += Number(user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
            }
            setTotal(temp);
        }
    }, [user.cartProducts])

    const handleShow = () => {
        setShow(true)
    };

  return (
    <>
        <div className='qr-menu-cart'>
            <button className='main-btn btn-cart' onClick={handleShow} id='cart-button'>
                <i className='icon-cart'></i>
                {total > 0 ?
                    <div className='vis-none'>
                        {Utils.numberWithSpaces(total)} {app.localizationsItems?.currency} <span className='count'>|</span>
                    </div>
                    :
                    <div className='vis-none'>
                        {app.localizationsItems?.cart} <span className='count'>|</span>
                    </div>
                }
                <span className='count count-icon'>{user.cartProducts?.length ? user?.cartProducts?.length : 0}</span>
            </button>
        </div>
        {show && <CartModal show={show} setShow={setShow}/>}
    </>
  )
})

export default CartButton