import React from 'react'

const ProductsLoading = () => {
    const skeletonItems = Array.from({ length: 6 }, (_, index) => (
        <div className='product-loading-item' key={index}>
            <div className='product-loading-img'></div>
            <div className='product-loading-title'></div>
            <div className='product-loading-price'></div>
        </div>
    ));
      
  return (
    <div className="container">
        <div className='loading-top'></div>
        <div className='product-loading'>
            {skeletonItems}
        </div>
        <div className='flare'></div>
    </div>
  )
}

export default ProductsLoading