import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';

const SamplePage = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();
    const {slug} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/page/${slug}`);
            }
        }
    }, [app.lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, slug)

  return (
    <div className='sample-page'>
        {loading ?
            <div className='loader-wrapper'>
                <span className='loader'/>
            </div>
            :
            items &&
            <div className='container'>
                <h2 className="mb-30">{items.title}</h2>
                <div dangerouslySetInnerHTML={{__html: items.description}}></div>
            </div>
        }
    </div>
  )
})

export default SamplePage