import React, { useEffect, useState } from 'react'

const ToTopButton = () => {
    const [visible, setVisible] = useState();

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
    };
      
    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

  return (
    visible &&
    <div className='top-button' onClick={scrollToTop}>
        <i className='icon-chevron-up'></i>
    </div>
  )
}

export default ToTopButton