import React, { useContext, useEffect, useRef, useState } from 'react'
import LoginModal from '../modals/LoginModal';
import LoginCodeModal from '../modals/LoginCodeModal';
import { NavLink, useNavigate } from 'react-router-dom';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserServices from '../../services/general/userServices';
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook';
import AuthServices from '../../services/general/authServices';
import BonusModal from '../modals/BonusModal';
import Utils from '../../services/utils';
import RegistrationModal from '../modals/RegistrationModal';
import ForgotPasswordModal from '../modals/ForgotPasswordModal';
import UpdatePasswordModal from '../modals/UpdatePasswordModal';
import CheckCodeModal from '../modals/CheckCodeModal';

const UserIcon = observer(({handleClose}) => {
    const {app, user} = useContext(Context);

    const dropdownRef = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

    const [token, setToken] = useState(localStorage.getItem('token'));

    const [show, setShow] = useState();
    const [codeShow, setCodeShow] = useState();

    const [registrationModal, setRegistrationModal] = useState();
    const [forgotPasswordModal, setForgotPasswordModal] = useState();
    const [updatePasswordModal, setUpdatePasswordModal] = useState();
    const [sendCodeModal, setSendCodeModal] = useState();

    const [bonusShow, setBonusShow] = useState();
    const [phone, setPhone] = useState();
    const [info, setInfo] = useState();

    const [email, setEmail] = useState();
    const [code, setCode] = useState();

    const navigate = useNavigate();

    const fetchUser = async () => {
        const res = await UserServices.profile.index();

        if(res.statusCode === 200){
            user.setUser(res.content)
            setInfo(res.content)
        }
    }

    useEffect(() => {
        fetchUser();
        setToken(user.token)
    }, [user.tokenChange])

    const handleLogin = () => {
        setShow(true)
        handleClose();
    }

    const logOut = async () => {
        const res = await AuthServices.logOut();
        
        if (res.statusCode === 200) {
            user.setUser({})
            user.setBalance(0)
            user.removeToken()
            user.setBasketChange(Math.random().toString(16));
            user.setTokenChange(Math.random().toString(16));
            navigate(`/${app.lang}`);
            setInfo(null)
        }
        setIsActive(false)
    }

  return (
    <>
        {app.bonusEnabled ?
            <>
                {info?.balance > 0 ?
                    <div className='user-bonuses' onClick={() => setBonusShow(true)}>
                        <i className='icon-coin'></i>
                        {Utils.numberWithSpaces(info?.balance)}
                    </div>
                    :
                    <div className='user-bonuses' onClick={() => setBonusShow(true)}>
                        <i className='icon-coin'></i>
                        0
                    </div>}
                </>
            :
            <></>
        }
        {token ?
            <>
                <div className='user-icon' ref={dropdownRef}>
                    <div className='user-icon-title' onClick={() => setIsActive(!isActive)}>
                        <i className='icon-user-2'></i> {app.localizationsItems?.cabinet}
                    </div>
                    <div className={isActive ? 'user-icon-content active' : 'user-icon-content'}>
                        <NavLink className='user-icon-item' to={`/${app.lang}/user`} onClick={() =>setIsActive(false)}>
                            <i className='icon-user-thin'></i>
                            {app.localizationsItems?.my_data}
                        </NavLink>
                        <NavLink className='user-icon-item' to={`/${app.lang}/user/orders`} onClick={() =>setIsActive(false)}>
                            <i className='icon-bill'></i>
                            {app.localizationsItems?.my_orders}
                        </NavLink>
                        <NavLink className='user-icon-item' to={`/${app.lang}/user/addresses`} onClick={() =>setIsActive(false)}>
                            <i className='icon-pin'></i>
                            {app.localizationsItems?.all_addresses}
                        </NavLink>
                        <NavLink className='user-icon-item' to={`/${app.lang}/user/notifications`} onClick={() =>setIsActive(false)}>
                            <i className='icon-chat-1'></i>
                            {app.localizationsItems?.notifications}
                        </NavLink>
                        {/*<NavLink className='user-icon-item' to={`/${app.lang}/user/settings`} onClick={() =>setIsActive(false)}>
                            <i className='icon-settings'></i>
                            {app.localizationsItems?.settings}
                        </NavLink>*/}
                        <div className='user-icon-item' onClick={logOut}>
                            <i className='icon-exit'></i>
                            {app.localizationsItems?.logout}
                        </div>
                    </div>
                </div>
            </>
            :
            <button className='main-btn btn-outline' onClick={handleLogin}>
                {app.localizationsItems?.login}
            </button>
        }
        {show && <LoginModal 
            show={show} 
            setShow={setShow} 
            setCodeShow={setCodeShow} 
            setPhone={setPhone}
            setRegistrationModal={setRegistrationModal}
            setForgotPasswordModal={setForgotPasswordModal}
        />}
        {codeShow && <LoginCodeModal show={codeShow} setShow={setCodeShow} phone={phone}/>}
        {bonusShow && <BonusModal show={bonusShow} setShow={setBonusShow}/>}
        {registrationModal &&
            <RegistrationModal
                show={registrationModal}
                setShow={setRegistrationModal}
            />
        }
        {forgotPasswordModal &&
            <ForgotPasswordModal
                show={forgotPasswordModal}
                setShow={setForgotPasswordModal}
                setSendCodeModal={setSendCodeModal}
                setEmail={setEmail}
            />
        }
        {sendCodeModal &&
            <CheckCodeModal
                show={sendCodeModal}
                setShow={setSendCodeModal}
                setUpdatePasswordModal={setUpdatePasswordModal}
                email={email}
                setCode={setCode}
            />
        }
        {updatePasswordModal &&
            <UpdatePasswordModal
                show={updatePasswordModal}
                setShow={setUpdatePasswordModal}
                email={email}
                code={code}
            />
        }
    </>
  )
})

export default UserIcon