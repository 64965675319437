import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { Context } from '../..';
import DeleteProfileModal from "../modals/DeleteProfileModal"
import EditProfileModal from '../modals/EditProfileModal';
import { NavLink, useNavigate } from 'react-router-dom';
import PorfileBonus from '../simple/PorfileBonus';

const Dashboard = observer(() => {
    const {app, user} = useContext(Context);
    const [deleteModal, setDeleteModal] = useState();
    const [editModal, setEditModal] = useState();

    const navigate = useNavigate();

  return (
    <div className='dashboard'>
        <div className='button-back'>
            <i className='icon-chevron-left' onClick={() => navigate(`/${app.lang}/mob-user`)}></i>
            <h3 className='mob-visible'>{user.user.name  ? user.user.name : app.localizationsItems?.my_data}</h3>
        </div>
        {app.bonusEnabled ? <PorfileBonus/> : <></>}
        <div className='profile-dashboard profile-white'>
            <div className="flex align-center mb-30">
                <h4>{app.localizationsItems?.profile}</h4>
                <NavLink to={`/${app.lang}/user/dashboard/edit`} className='span'>
                    {app.localizationsItems?.edit}
                    <i className='icon-pen'></i>
                </NavLink>
            </div>
            {user.user &&
                <div className='profile-dashboard-wrapper'>
                    <div>
                        <span>{app.localizationsItems?.name}</span>
                        <p>{user.user.name  ? user.user.name : '-'}</p>
                    </div>
                    <div>
                        <span>{app.localizationsItems?.email}</span>
                        <p>{user.user.email ? user.user.email : '-'}</p>
                    </div>
                    <div>
                        <span>{app.localizationsItems?.phone}</span>
                        <p>{user.user.phone ? user.user.phone : '-'}</p>
                    </div>
                    <div>
                        <span>{app.localizationsItems?.birthday}</span>
                        <p>{user.user.date_of_birth ? user.user.date_of_birth : '-'}</p>
                    </div>
                    <div>
                        <span>{app.localizationsItems?.gender}</span>
                        <p>{user.user.gender === 'female' ? `${app.localizationsItems?.female}`: user.user.gender === 'male' ? `${app.localizationsItems?.male}` : '-'}</p>
                    </div>
                </div>
            }
            <div className='delete-container'>
                <div></div>
                <span onClick={() => setDeleteModal(true)}>
                    {app.localizationsItems?.delete_profile}
                    <i className='icon-remove'></i>
                </span>
            </div>
            {deleteModal && <DeleteProfileModal show={deleteModal} setShow={setDeleteModal}/>}
            {editModal && <EditProfileModal show={editModal} setShow={setEditModal}/>}
        </div>
    </div>
  )
})

export default Dashboard